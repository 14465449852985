import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  getAccountTransaction,
} from '../functions'
const HistoryLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  addr,
  initialBidHistory,
  initialLastBid,
  initialIsAuctionOver,
  token,
  decimals,
  children
}) => {
  const [state, setState] = useState({
    showing: true,
    bidHistory: initialBidHistory,
    lastBid: initialLastBid,
    isAuctionOver: initialIsAuctionOver
  })
  useEffect(() => {
    let bidHistoryDepth = initialBidHistory.length
    let timeout = 7000
    let interval = setInterval(() => {
      (async () => {
        console.log(new Date())
        let transactions = (await getAccountTransaction(addr))?.data?.transactions
        const [lastBid, ...bidHistory]
          = transactions
            .filter(el => true
              && el['tx-type'] === 'axfer'
              && el['asset-transfer-transaction']['asset-id'] !== token
              && el['asset-transfer-transaction'].receiver === addr)
            .map((el) => ({
              time: moment.unix(el['round-time']).format('LTS'),
              sender: el?.sender,
              amount: stdlib.formatCurrency(el["asset-transfer-transaction"]?.amount) / Math.pow(10, decimals - 6)
            }))
        const newBidHistory = bidHistory
        const newBidHistoryDepth = newBidHistory.length
        if (bidHistoryDepth >= newBidHistory.length) {
          console.log({ bidHistoryLoader: "pass" })
          return
        }
        bidHistoryDepth = newBidHistoryDepth
        setState({
          showing: true,
          bidHistory: newBidHistory,
          lastBid,
        })
      })()
    }, timeout)
    return () => clearInterval(interval)
  }, [stdlib, acc, addr, id, state])
  return (
    state
      ? state.showing && React.Children.map(children, (child, i) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child,
            { ...state, key: i });
        }
        return child;
      })
      : <div id="loading"></div>
  )
}
export default HistoryLoader;